<template>
  <v-app>
    <div class="row" v-if="loading && page && page.length<1">
      <div class="col-md-12">
        <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
      </div>
    </div>
    <div class="row" v-else-if="!loading && page && page.length<1">
      <div class="col-md-12 text-center">
        <img src="@/assets/media/icons/no-data-found.png" class="no-data-img">
        <h5 class="mt-10">Looks like you have not added any data here. Trying adding some data.</h5>
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="data"
      :items-per-page="itemsPerPage"
      hide-default-footer
      :single-expand="singleExpand"
      show-expand
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <div class="col-md-8 col-lg-8 col-sm-8">
            <v-toolbar-title>Reviews</v-toolbar-title>
          </div>
          <!-- <div class="col-md-4 col-lg-4 col-sm-4 text-right">
            <v-btn color="primary" dark class="mb-2" @click="addreview">
              <v-icon small>mdi-plus</v-icon>review
            </v-btn>
          </div>-->
        </v-toolbar>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">{{ item.description }}</td>
      </template>
      <template v-slot:item.is_parent="{ item }">
        <v-icon small :class="item.is_parent">{{item.is_parent}}</v-icon>
      </template>
      <template
        v-slot:item.reviewed_by="{ item }"
      >{{item.reviewed_by.first_name}} {{ item.reviewed_by.last_name}}</template>
      <template v-slot:item.is_verified_user="{ item }">
        <v-icon small :class="item.is_verified_user">{{item.is_verified_user}}</v-icon>
      </template>
      <template v-slot:item.is_active="{ item }">
        <i v-if="item.is_active" class="kt-nav__link-icon flaticon2-check-mark text-success"></i>
        <i v-else class="kt-nav__link-icon flaticon2-cancel-music text-danger"></i>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon v-if="!item.deleted_at" small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
      </template>
    </v-data-table>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-pagination
          v-model="page"
          :length="pageCount"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          @input="getReviews(page)"
          :total-visible="5"
        ></v-pagination>
      </v-col>
    </v-row>
    <v-dialog scrollable v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="headline">Want to Report this Review?</v-card-title>

        <v-card-text>Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogClose">Close</v-btn>
          <v-btn color="blue darken-1" text @click.prevent="sendReport">Send</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import OgranisationReviewService from "@/services/organisation/OrganisationReviewService";
const ogranisationReview = new OgranisationReviewService();

export default {
  name: "reviewPersonal",
  data() {
    return {
      icon: null,
      color: null,
      dialog: false,
      loading: true,
      review: {},
      singleExpand: true,
      headers: [
        { text: "Title", value: "title" },
        { text: "Rating", value: "rating" },
        { text: "Reviewed By", value: "reviewed_by" },
        { text: "Organisation", value: "organisation.title" },
        { text: "Review At", value: "reviewed_at" },
        { text: "Status", value: "is_active" },
        { text: "Action", value: "actions", sortable: false }
      ],
      data: [],
      itemsPerPage: 25,
      page: null,
      pageCount: null,
      per_page: null
    };
  },
  created() {
    this.getReviews();
  },
  methods: {
    getReviews(index = null) {
      ogranisationReview.paginate(index).then(response => {
        this.data = response.data.data;
        this.itemsPerPage = response.data.meta.per_page;
        this.page = response.data.meta.current_page;
        this.pageCount = response.data.meta.last_page;
        this.loading = false
      });
    },
    dialogClose: function() {
      this.dialog = !this.dialog;
      this.data = [];
      this.review = [];
      this.getReviews();
    },
    addreview: function() {
      this.dialog = !this.dialog;
    },
    editItem: function(item) {
      this.dialog = !this.dialog;
      this.review = item;
    },
    sendReport() {
      this.dialogClose();
    },
    deleteItem: function(item) {
      if (confirm("Are you sure you want to delete this item?")) {
        ogranisationReview
          .delete(item.id)
          .then(() => {
            this.data = [];
            this.getReviews();
          })
          .catch(err => {

          });
      }
    }
  },
  computed: {
    rows() {
      return this.items.length;
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Reviews", route: "dashboard" }
    ]);
  }
};
</script>
