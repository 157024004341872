var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(_vm.loading && _vm.page && _vm.page.length<1)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"table"}})],1)]):(!_vm.loading && _vm.page && _vm.page.length<1)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 text-center"},[_c('img',{staticClass:"no-data-img",attrs:{"src":require("@/assets/media/icons/no-data-found.png")}}),_c('h5',{staticClass:"mt-10"},[_vm._v("Looks like you have not added any data here. Trying adding some data.")])])]):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","single-expand":_vm.singleExpand,"show-expand":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('div',{staticClass:"col-md-8 col-lg-8 col-sm-8"},[_c('v-toolbar-title',[_vm._v("Reviews")])],1)])]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(_vm._s(item.description))])]}},{key:"item.is_parent",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{class:item.is_parent,attrs:{"small":""}},[_vm._v(_vm._s(item.is_parent))])]}},{key:"item.reviewed_by",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.reviewed_by.first_name)+" "+_vm._s(item.reviewed_by.last_name))]}},{key:"item.is_verified_user",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{class:item.is_verified_user,attrs:{"small":""}},[_vm._v(_vm._s(item.is_verified_user))])]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [(item.is_active)?_c('i',{staticClass:"kt-nav__link-icon flaticon2-check-mark text-success"}):_c('i',{staticClass:"kt-nav__link-icon flaticon2-cancel-music text-danger"})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.deleted_at)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]):_vm._e()]}}])}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"prev-icon":"mdi-menu-left","next-icon":"mdi-menu-right","total-visible":5},on:{"input":function($event){return _vm.getReviews(_vm.page)}},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1),_c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Want to Report this Review?")]),_c('v-card-text',[_vm._v("Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running.")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.dialogClose}},[_vm._v("Close")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){$event.preventDefault();return _vm.sendReport.apply(null, arguments)}}},[_vm._v("Send")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }